import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast, ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
const ChatBoatForm = () => {
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email format").required("Email is required"),
    OrganisationName: yup.string().required("Organisation Name is required"),
  });

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const { register, handleSubmit, formState: { errors }, reset } = methods;

  const onSubmit = async (data) => {
    const formData = {
      name: data.name,
      email: data.email,
      OrganisationName: data.OrganisationName
    };

    try {
      const response = await fetch("http://localhost:5000/Cform", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success('Welcome to Talk Our Expert Teams', {
          position: "top-center",
          transition: Slide
        });
        reset();
      } else {
        toast.error(responseData.message); // Show the error message from the backend
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="bg-white shadow-md rounded-md p-4 w-full h-screen">
      <ToastContainer />
      <div className="flex flex-col items-center justify-center w-full h-full">
        <h1 className="text-3xl font-bold mb-4">Please fill in the details below!</h1>
        <FormProvider {...methods}>
          <form className="w-1/4 mt-10" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <input
                type="text"
                id="name"
                placeholder='Name'
                className="w-full border border-gray-300 rounded-md p-2"
                {...register('name')}
              />
              {errors.name && <p className="text-red-500 text-sm">{errors.name.message}</p>}
            </div>
            <div className="mb-4">
              <input
                type="text"
                id="email"
                placeholder='Email'
                className="w-full border border-gray-300 rounded-md p-2"
                {...register('email')}
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
            </div>
            <div className="mb-4">
              <input
                type="text"
                id="OrganisationName"
                placeholder='Organisation Name'
                className="w-full border border-gray-300 rounded-md p-2"
                {...register('OrganisationName')}
              />
              {errors.OrganisationName && <p className="text-red-500 text-sm">{errors.OrganisationName.message}</p>}
            </div>
            <button type="submit" className="w-full mt-4 bg-blue-500 text-white rounded-md p-2 hover:bg-blue-600">
            <Link to="/ChatBot" className="text-white-500">Continue</Link>
                

            </button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

export default ChatBoatForm;
