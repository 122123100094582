import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { BsPeopleFill } from "react-icons/bs";
import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = () => {
  const [showForm, setShowForm] = useState(false);
  const formRef = useRef(null);

  const pitchSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().required("Email is required").email("Email is not valid"),
  });

  const salesSchema = yup.object().shape({
    frist_name: yup.string().required("First Name is required"),
    last_name: yup.string().required("Last Name is required"),
    title: yup.string().required("Title is required"),
    company_name: yup.string().required("Company Name is required"),
    business_email: yup.string().required("Business Email is required").email("Business Email is not valid"),
    country: yup.string().required("Country is required"),
    business_phone: yup.string().required("Business Phone is required"),
    comment: yup.string().required("Comment is required"),
  });

  const {
    register: pitchRegister,
    handleSubmit: pitchHandleSubmit,
    formState: { errors: pitchErrors },
    reset: pitchReset,
  } = useForm({
    resolver: yupResolver(pitchSchema),
  });

  const {
    register: salesRegister,
    handleSubmit: salesHandleSubmit,
    formState: { errors: salesErrors },
    reset: salesReset,
  } = useForm({
    resolver: yupResolver(salesSchema),
  });

  const onSubmitPitch = async (data) => {
    const formData = {
      name: data.name,
      email: data.email,
    };

    try {
      const response = await fetch("https://prolific-be.globalxperts.net/PitchUs", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success('Thank you for the information. Our Sales Team will reach out to you', {
          position: "top-center",
          transition: Slide,
          autoClose: 2000 // Delay in milliseconds (2 seconds)
        });
        pitchReset();
      } else {
        window.alert(responseData.message); // Show the error message from the backend
      }
    } catch (error) {
      console.error('Error in onSubmitPitch:', error);
      window.alert('An error occurred while submitting the form.');
    }
  };

  const onSubmitSales = async (data) => {
    const formData = {
      frist_name: data.frist_name,
      last_name: data.last_name,
      title: data.title,
      company_name: data.company_name,
      business_email: data.business_email,
      country: data.country,
      business_phone: data.business_phone,
      comment: data.comment
    };

    try {
      const response = await fetch("https://prolific-be.globalxperts.net/SpackWithSales", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success('Thank you for the information. Our Sales Team will reach out to you', {
          position: "top-center",
          transition: Slide,
          autoClose: 2000 // Delay in milliseconds (2 seconds)
        });
        salesReset();
      } else {
        window.alert(responseData.message); // Show the error message from the backend
      }
    } catch (error) {
      console.error('Error in onSubmitSales:', error);
      window.alert('An error occurred while submitting the form.');
    }
  };

  const handleClick = () => {
    setShowForm(true);
    setTimeout(() => {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  return (
    <>
      <div className="w-full">
        <div className="mx-4 sm:mx-8 md:mx-10 lg:mx-12 mt-12">
          <img
            className="h-12 w-44 mt-4"
            src={require("../src/asset/logo 1.png")}
            alt=""
          />
        </div>
        <div className="flex flex-col lg:flex-row gap-10 py-5 mx-4 sm:mx-8 md:mx-10 lg:mx-12">
          <div className="mt-5 w-full lg:w-1/2">
            <h1 className="text-3xl text-blue-900 font-sans font-semibold mt-2">Get In Touch</h1>
            <p className="font-normal text-gray-600 mt-7">
              Whether you're a new user seeking assistance or a GXI customer starting your
              journey, we're here to help you find the answers.
            </p>
            <div className="mt-10">
              <h1 className="text-3xl text-blue-900 font-sans font-semibold">Meet us</h1>
              <div className="border rounded-full px-5 mt-5 mb-5 bg-customBlue">
                <p className="font-normal text-gray-500 flex gap-2 mt-7">
                  <FaPhoneAlt className="mt-1" />
                  7991846291
                </p>
                <p className="font-normal text-gray-500 flex gap-2">
                  <MdOutlineMailOutline className="mt-1" />
                  omkarjaiswal646@gmail.com
                </p>
                <p className="font-normal text-gray-500 flex gap-2 mb-5">
                  <IoLocationOutline className="mt-1" />
                  902, Tower B, Urbtech Trade Center, Sector 132, Noida, Uttar Pradesh 201304
                </p>
              </div>
            </div>
            <div className="mt-10">
              <form onSubmit={pitchHandleSubmit(onSubmitPitch)}>
                <p className="text-3xl text-blue-900 font-sans font-semibold">Pitch us</p>
                <div className="border rounded-lg px-5 mt-5 mb-10">
                  <p className="font-normal text-gray-500 mt-7">Hello,</p>
                  <p className="font-normal text-gray-500 mt-2">
                    My Name is <input type="text" className="border-b w-36" {...pitchRegister('name')} /> and my
                  </p>
                  <p className="mt-2 font-normal text-gray-500">
                    email address is <input type="email" className="border-b" {...pitchRegister('email')} />
                  </p>
                  <p className="mt-2 font-normal text-gray-500">and I would like to discuss about this project.</p>
                  {pitchErrors.name && <p className="text-red-500 text-md italic">{pitchErrors.name.message}</p>}
                  {pitchErrors.email && <p className="text-red-500 text-md italic">{pitchErrors.email.message}</p>}
                  <div className="flex justify-end">
                    <button type="submit" className="py-1 px-5 text-white font-semibold text-center border border-green-800 bg-blue-900 rounded-full mt-2 mb-5">SEND</button>
                  </div>
                </div>
              </form>
            </div>
            <div className="mt-10 h-14 w-full lg:w-60 flex justify-center rounded-full border cursor-pointer" onClick={handleClick}>
              <h1 className="mt-4 font-bold text-blue-900 gap-2 flex">
                <BsPeopleFill className="mt-1" /> SPEAK WITH SALES
              </h1>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <img className="w-full h-auto" src={require("../src/asset/cust3.avif")} alt="" />
          </div>
        </div>
        {showForm && (
          <form ref={formRef} onSubmit={salesHandleSubmit(onSubmitSales)} className="py-5 mx-4 sm:mx-8 md:mx-10 lg:mx-12">
            <div className="flex flex-wrap">
              <div className="w-full lg:w-1/2 px-2">
                <div className="space-y-2">
                  <p className="block text-lg font-serif text-blue-900 mt-3 font-medium">FIRST NAME:</p>
                  <input
                    type="text"
                    placeholder="Your answer"
                    {...salesRegister("frist_name")}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-900"
                  />
                  {salesErrors.frist_name && (
                    <p className="text-red-500 text-md italic">{salesErrors.frist_name.message}</p>
                  )}
                </div>
              </div>
              <div className="w-full lg:w-1/2 px-2">
                <div className="space-y-2">
                  <p className="block text-lg font-serif text-blue-900 mt-3 font-medium">TITLE:</p>
                  <input
                    type="text"
                    placeholder="Your answer"
                    {...salesRegister("title")}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-900"
                  />
                  {salesErrors.title && (
                    <p className="text-red-500 text-md italic">{salesErrors.title.message}</p>
                  )}
                </div>
              </div>
              <div className="w-full lg:w-1/2 px-2">
                <div className="space-y-2">
                  <p className="block text-lg font-serif text-blue-900 mt-3 font-medium">LAST NAME:</p>
                  <input
                    type="text"
                    placeholder="Your answer"
                    {...salesRegister("last_name")}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-900"
                  />
                  {salesErrors.last_name && (
                    <p className="text-red-500 text-md italic">{salesErrors.last_name.message}</p>
                  )}
                </div>
              </div>
              <div className="w-full lg:w-1/2 px-2">
                <div className="space-y-2">
                  <p className="block text-lg font-serif text-blue-900 mt-3 font-medium">COMPANY NAME:</p>
                  <input
                    type="text"
                    placeholder="Your answer"
                    {...salesRegister("company_name")}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-900"
                  />
                  {salesErrors.company_name && (
                    <p className="text-red-500 text-md italic">{salesErrors.company_name.message}</p>
                  )}
                </div>
              </div>
              <div className="w-full lg:w-1/2 px-2">
                <div className="space-y-2">
                  <p className="block text-lg font-serif text-blue-900 mt-3 font-medium">BUSINESS EMAIL:</p>
                  <input
                    type="text"
                    placeholder="Your answer"
                    {...salesRegister("business_email")}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-900"
                  />
                  {salesErrors.business_email && (
                    <p className="text-red-500 text-md italic">{salesErrors.business_email.message}</p>
                  )}
                </div>
              </div>
              <div className="w-full lg:w-1/2 px-2">
                <div className="space-y-2">
                  <p className="block text-lg font-serif text-blue-900 mt-3 font-medium">COUNTRY:</p>
                  <input
                    type="text" placeholder="Your answer"
                    {...salesRegister("country")}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-900"
                  />
                  {salesErrors.country && (
                    <p className="text-red-500 text-md italic">{salesErrors.country.message}</p>
                  )}
                </div>
              </div>
              <div className="w-full lg:w-1/2 px-2">
                <div className="space-y-2">
                  <p className="block text-lg font-serif text-blue-900 mt-3 font-medium">BUSINESS PHONE:</p>
                  <input
                    type="text" placeholder="Your answer"
                    {...salesRegister("business_phone")}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-900"
                  />
                  {salesErrors.business_phone && (
                    <p className="text-red-500 text-md italic">{salesErrors.business_phone.message}</p>
                  )}
                </div>
              </div>
              <div className="w-full lg:w-1/2 px-2">
                <div className="space-y-2">
                  <p className="block text-lg font-serif text-blue-900 mt-3 font-medium">COMMENTS:</p>
                  <input
                    type="text" placeholder="Your answer"
                    {...salesRegister("comment")}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-900"
                  />
                  {salesErrors.comment && (
                    <p className="text-red-500 text-md italic">{salesErrors.comment.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-10 flex justify-center">
              <button type="submit" className="py-2 w-full lg:w-80 text-blue-900 border font-bold border-blue-900 rounded-full">SUBMIT</button>
            </div>
          </form>
        )}
      </div>
      <div className="mt-5 border-2 py-5 mx-4 sm:mx-8 md:mx-10 lg:mx-12">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56092.46188634249!2d77.30363488197325!3d28.5163001093417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce7dc8f60e537%3A0x6143d8520c47bf41!2sGlobalXperts%20Inc%20%7C%20GXI%20Networks%20Private%20Limited!5e0!3m2!1sen!2sin!4v1719409755532!5m2!1sen!2sin"
          width="100%"
          height="400"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <ToastContainer />
    </>
  );
};

export default ContactUs;
