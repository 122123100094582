import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { toast, ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoIosArrowBack } from "react-icons/io";

//List of commonly used disposable or fake email addresses
// const fakeEmails = [
//   'test@example.com',
//   "test1@example.com",
//   "test2@example.com",
//   "test3@example.com",
//   "test4@example.com",
//   "test5@example.com",
//   "test6@example.com",
//   'example@example.com',
//   'example1@example.com',
//   'example2@example.com',
//   'example3@example.com',
//   'example4@example.com',
//   'admin@example.com',
//   'admin1@example.com',
//   'admin2@example.com',
//   'user@example.com',
//   'user1@example.com',
//   'user2@example.com',
//   // Add more as needed
// ];

//const isFakeEmail = (email) => fakeEmails.includes(email.toLowerCase());

const OnBoarding = () => {
  const location = useLocation();
  const email = location.state ? location.state.email : '';
  const message = location.state ? location.state.message : '';

  const [submitted, setSubmitted] = useState(false);
  const [hasAWSAccount, setHasAWSAccount] = useState(false);

  const schema = yup.object().shape({
    // name: yup.string().required("Name is required"),
    // OrganisationName: yup.string().required("Organisation Name is required"),
    // email: yup.string()
      
    // .required("Email is required"),
    // .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email domain')
    //   .test('is-fake-email', 'Enter Valid Email', value => !isFakeEmail(value)),
    // phone: yup.string()
    //   .matches(
    //     /^[2-9]{1}[0-9]{9}$/,
    //     "Invalid phone number"
    //   )
    //   .notRequired()
    //   .nullable()
    //   .default('') // Make phone optional
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    context: { hasAWSAccount },
  });

  const { register, handleSubmit, formState: { errors }, reset } = methods;

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    const formData = {
      name: data.name,
      OrganisationName: data.OrganisationName,
      email: data.email,
      phone: data.phone || '' // Include phone only if it exists, otherwise set it to an empty string
    };

    try {
      const response = await fetch("https://prolific-be.globalxperts.net/Boarding", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        toast.success('One of Our Experts will contact you shortly', {
          position: "top-center",
          transition: Slide
        });
        reset();
        setSubmitted(true);
      } else {
        const responseData = await response.json();
        window.alert(responseData.message); // Show the error message from the backend
      }
    } catch (error) {
      console.error('Error:', error);
      window.alert('An error occurred while submitting the form.');
    }
  };

  useEffect(() => {
    if (submitted) {
      const timer = setTimeout(() => {
        // Implement any action you need to take after form submission
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [submitted, navigate]);

  const handleChatWithExpertClick = () => {
    navigate("/", { state: { showChatBot: true, hideSignUp: true } });
  };

  // const handleBackClick = () => {
  //   navigate(-1); // Navigate to the previous page
  // };


  return (
    <div className="bg-white shadow-md rounded-md p-2 w-full h-screen flex flex-col justify-center items-center relative">
     
     <div className="absolute top-2 right-4 flex items-center w-full">
  <div className="flex items-center justify-start flex-grow"> {/* Left side */}
    <button  className="flex items-center text-left mx-7"><Link to="/SignUp" className=" flex ">
      <IoIosArrowBack className="text-xl font-bold mt-1" />
      <span className="ml-1 font-bold">Back</span></Link>
    </button>
  </div>

  <div className="flex items-center justify-end"> {/* Right side */}
    <p className="text-right text-transparent font-bold bg-gradient-to-r from-blue-500 to-green-500 bg-clip-text">{email}</p>
    <img
      className="h-8 w-8 ml-2"
      src={require("../src/asset/user.png")}
      alt="User Icon"
    />
  </div>
</div>


      <ToastContainer />

      <div className="flex flex-col items-center justify-center w-full">
        <img
          className="h-32 w-52"
          src={require("../src/asset/Gxi.png")}
          alt="Logo"
        />
        <h1 className="text-2xl font-bold mb-4">OnBoarding Details</h1>
        <FormProvider {...methods}>
          <form className="w-full max-w-md mt-16" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex mb-4">
              <div className="w-1/2 mr-2">
                <input
                  type="text"
                  id="name"
                  placeholder='Full Name'
                  className="w-full border border-gray-300 rounded-md p-2"
                  {...register('name')}
                />
                {errors.name && <p className="text-red-500 text-sm">{errors.name.message}</p>}
              </div>
              <div className="w-1/2 ml-2">
                <input
                  type="text"
                  id="OrganisationName"
                  placeholder='Organisation Name'
                  className="w-full border border-gray-300 rounded-md p-2"
                  {...register('OrganisationName')}
                />
                {errors.OrganisationName && <p className="text-red-500 text-sm">{errors.OrganisationName.message}</p>}
              </div>
            </div>
            <div className="flex mb-4">
              <div className="w-1/2 mr-2">
                <input
                  type="text"
                  id="email"
                  placeholder='Email'
                  className="w-full border border-gray-300 rounded-md p-2"
                  {...register('email')}
                />
                {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
              </div>
              <div className="w-1/2 ml-2">
                <input
                  type="text"
                  id="phone"
                  placeholder='Phone'
                  className="w-full border border-gray-300 rounded-md p-2"
                  {...register('phone')}
                />
                {errors.phone && <p className="text-red-500 text-sm">{errors.phone.message}</p>}
                <h5 className='font-bold text-black text-sm'>*****it is optional*****</h5>
              </div>
            </div>
            <button type="submit" className="w-full bg-blue-500 text-white rounded-md p-2 hover:bg-blue-600">Continue</button>
          </form>
        </FormProvider>
        <div className="mt-4">
          <div className="flex items-center justify-center w-full border-b border-gray-300 pb-2">
            <span className="mr-2">OR</span>
          </div>
          <button type='button' className="w-full bg-blue-500 text-gray-700 rounded-md p-2 mt-4 px-5 hover:bg-gray-300" onClick={handleChatWithExpertClick}>
            <Link to="/" className="text-white">Chat with an Expert</Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default OnBoarding;
