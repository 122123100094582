import React, { useState } from "react";
import ChatBot from "./Chat";
import { faL } from "@fortawesome/free-solid-svg-icons";
import ChatBot2 from "./Chatbot2";
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { TbInfoTriangle } from "react-icons/tb";
import { MdOutlinePhone } from "react-icons/md";
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import backgroundImage from "../src/asset/bg-img.jpg";

const Form = () => {
  const location = useLocation();
  const email = location.state?.email || "";


  const schema = yup.object().shape({
   
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    
  });

  const { register, handleSubmit, formState: { errors }, reset ,watch } = methods;


  const onSubmit = async (data, event) => {
    try {
      const formData = {
        TypeOfApplication: data.TypeOfApplication || "",
        ApplicationBaseline: data.ApplicationBaseline || "",
        Expected_IOPs: data.Expected_IOPs || "",
        GB_Required: data.GB_Required || "",
        frequency: data.frequency || "",
        needDynamic_IOPs: data.needDynamic_IOPs || "",
        time: data.time || "",
        AWS_Account_ID: data.AWS_Account_ID || "",
        ou: data.ou || "",
        region: data.region || "",
        VPC_Name_Name: data.VPC_Name_Name || "",
        Securit_Group_Name: data.Securit_Group_Name || "",
        Placement_Group: data.Placement_Group || ""
      };
  
      const response = await fetch("https://prolific-be.globalxperts.net/Form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        toast.success(
          "Your details have been submitted successfully! One of Our Experts will contact you shortly",
          {
            position: "top-center",
            transition: Slide,
          }
        );
  
        // Create PDF
        const pdf = new jsPDF();
        pdf.text(20, 20, 'Form Data');
        let y = 30; // Starting Y-coordinate for text
        Object.entries(formData).forEach(([key, value]) => {
          pdf.text(20, y, `${key}: ${value}`);
          y += 10; // Increment Y-coordinate for next line
        });
  
        // Save PDF
        pdf.save('form-data.pdf');
        reset(); // Clear the form inputs
      } else {
        throw new Error("Error: " + response.status);
      }
    } catch (error) {
      console.error("Error:", error);
      alert(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };



  const navigate = useNavigate();

  console.log(handleSubmit, "this is full data");
 
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [showContactInfo2, setShowContactInfo2] = useState(false);
  const [showContactInfo3, setShowContactInfo3] = useState(false);
  const [showContactInfo4, setShowContactInfo4] = useState(false);
  const [showContactInfo5, setShowContactInfo5] = useState(false);
  const [showContactInfo6, setShowContactInfo6] = useState(false);
  const [showContactInfo7, setShowContactInfo7] = useState(false);
  const [showContactInfo8, setShowContactInfo8] = useState(false);
  const [showContactInfo9, setShowContactInfo9] = useState(false);
  const [showContactInfo10, setShowContactInfo10] = useState(false);
  const [showContactInfo11, setShowContactInfo11] = useState(false);

  const handleContactUsClick = () => {
    setShowContactInfo(!showContactInfo);
  };

  const handleContactUsClick2 = () => {
    setShowContactInfo2(!showContactInfo2);
    
  };

  const handleContactUsClick3 = () => {
    setShowContactInfo3(!showContactInfo3);
  };

  const handleContactUsClick4 = () => {
    setShowContactInfo4(!showContactInfo4);
  };

  const handleContactUsClick5 = () => {
    setShowContactInfo5(!showContactInfo5);
  };

  const handleContactUsClick6 = () => {
    setShowContactInfo6(!showContactInfo6);
  };

  const handleContactUsClick7 = () => {
    setShowContactInfo7(!showContactInfo7);
  };

  const handleContactUsClick8 = () => {
    setShowContactInfo8(!showContactInfo8);
  };

  const handleContactUsClick9 = () => {
    setShowContactInfo9(!showContactInfo9);
  };

  const handleContactUsClick10 = () => {
    setShowContactInfo10(!showContactInfo10);
  };
  const handleContactUsClick11 = () => {
    setShowContactInfo11(!showContactInfo11);
  };

  const selectedValues = watch(); // Watch for form values
  const validationErrors = errors; // Form validation errors


  return (
    <>
      <div
        className="bg-cover bg-center h-100%"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="w-20 h-2 mx-2 ">
          <button className="flex items-center  text-white">
            <Link to="/SignUp" className=" flex text-white mt-5 ">
              <IoIosArrowBack className="text-xl font-bold mt-1" />
              <span className="ml-1 font-bold">Back</span>
            </Link>
          </button>
        </div>

        <div className="from-purple-600 to-blue-600 ">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "700px" }}>
              <div className=" w-full">
                <div className="absolute top-2 right-4 flex items-center mt-5">
                  <p className="text-right text-transparent font-bold bg-gradient-to-r from-blue-500 to-green-500 text-white bg-clip-text">
                    {email}
                  </p>
                  {/* <img
          className="h-8 w-8 ml-2"
          src={require("../src/asset/user.png")}
          alt="User Icon"
        /> */}
                </div>
              </div>

              <div className="w-full mt-10 bg-white  shadow-xl rounded px-8 pt-6 pb-8 mb-4 ">
                <div className="flex gap-8 justify-between">
                  <img
                    className="h-10 w-52 rounded-lg object-contain mt-5"
                    src={require("../src/asset/logo 1.png")}
                    alt="Architecture"
                  />
                  <img
                    className="h-16  w-40 rounded-lg "
                    src={require("../src/asset/IOSA-crisp-logo-v2 2.png")}
                    alt="Architecture"
                  />
                </div>

                <div>
                  <h3 className="text-center text-lg font-bold mt-10 ">
                    Answer the questions below with respect to the application
                    for Prolific I/O
                  </h3>
                  <p className=" font-bold text-xs text-center">Please click on the 'Info' for the Information and 'contact us' if you would like to speak to our SME</p>
                </div>

                <div className="form-container mt-2">
                  <form onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="form-group py-2" id="app-type">
                      <label htmlFor="app-type" className=" text-sm">
                        <p className="flex items-center italic font-sans">
                          1. Type of application that require high IOPs (SAP,
                          Oracle, etc) <p className=" text-gray-300 mx-1">|</p>
                          <a
                            href="https://drive.google.com/file/d/1MVs66krDAo4FH9l0t1XrgyAab7iBegwl/view?usp=drivesdk"
                            className="text-blue-500 mx-2 flex items-center underline"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <TbInfoTriangle className="mt-1 mx-1" />
                            info
                          </a>{" "}
                          |
                          <span
                            className="mx-2 flex items-center cursor-pointer text-blue-500 underline"
                            onClick={handleContactUsClick}
                          >
                            <MdOutlinePhone className="mt-1 mr-1" /> Contact us
                          </span>
                        </p>
                        <div className="focus:border-gray-500 border-gray-500 mt-3">
                          <input
                            type="text"
                            id="app-type-text"
                            name="TypeOfApplication"
                            {...register('TypeOfApplication')}
                            placeholder="Enter type of application that require high IOPs (like: SAP, Oracle, SQL, etc)"
                            required
                            className="bg-white border focus:border-gray-300 border-gray-400 text-sm focus:ring-blue-500 block w-full p-2.5"
                          />
                        </div>
                        {showContactInfo && (
                          <div className="mt-2 text-green-900 font-semibold">
                            Ph: 1234567890 | e-mail: support@gxinetworks.com
                          </div>
                        )}
                      </label>
                    </div>

                    <div className="form-group  py-2" id="baseline-iops">
                      <label
                        htmlFor="baseline-iops"
                        className=" text-sm"
                      >
                        <p className="flex items-center italic font-sans">
                          {" "}
                          2. What is your application Baseline IOPs<p className=" text-gray-300 mx-1">|</p>
                          <a
                            href="https://drive.google.com/file/d/1MXxevY8lR_XYh-3N4Ap_a_wzy-n0b5-o/view?usp=drivesdk"
                            className="text-blue-500 mx-2 flex items-center underline"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <TbInfoTriangle className="mt-1" />
                            info
                          </a>{" "}
                          |
                          <span
                            className="mx-2 flex items-center cursor-pointer text-blue-500 underline"
                            onClick={handleContactUsClick2}
                          >
                            <MdOutlinePhone className="mt-1 mr-1" /> Contact us
                          </span>
                        </p>
                        <div className="focus:border-gray-500 border-gray-500 mt-3">
                          <input
                            type="text"
                            id="app-type-text"
                            name="TypeOfApplication"
                            {...register('ApplicationBaseline')}
                            placeholder="Enter your application Baseline IOPs"
                            required
                            className="bg-white border focus:border-gray-300 border-gray-400 text-sm focus:ring-blue-500 block w-full p-2.5"
                          />
                        </div>
                        {showContactInfo2 && (
                          <div className="mt-2 text-green-900 font-semibold">
                            Ph: 1234567890 | e-mail: support@gxinetworks.com
                          </div>
                        )}
                      </label>
                    </div>

                    <div className="form-group mt-2" id="Expected_IOPs">
                      <label
                        htmlFor="Expected_IOPs"
                        className=" text-sm"
                      >
                        <p className=" flex items-center italic font-sans">
                          3. What is your expected IOPs (200,000 -
                          300,000/250GB)<p className=" text-gray-300 mx-1">|</p>
                          <a
                            href="https://drive.google.com/file/d/1MYbOpXnbElHnolq8AfytIJGNw8D5lORF/view?usp=drivesdk"
                            className="text-blue-500 mx-2 flex items-center underline"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <TbInfoTriangle className="mt-1" />
                            info
                          </a>{" "}
                          |
                          <span
                            className="mx-2 flex items-center cursor-pointer text-blue-500 underline"
                            onClick={handleContactUsClick3}
                          >
                            <MdOutlinePhone className="mt-1 mr-1" /> Contact us
                          </span>
                        </p>
                        <div className="focus:border-gray-500 border-gray-500 mt-3">
                          <input
                            type="text"
                            id="app-type-text"
                            name="TypeOfApplication"
                            {...register('Expected_IOPs')}
                            placeholder="Enter your expected IOPs"
                            required
                            className="bg-white border focus:border-gray-300 border-gray-400 text-sm focus:ring-blue-500 block w-full p-2.5"
                          />
                        </div>
                        {showContactInfo3 && (
                          <div className="mt-2 text-green-900 font-semibold">
                            Ph: 1234567890 | e-mail: support@gxinetworks.com
                          </div>
                        )}
                      </label>
                    </div>

                    <div className="form-group mt-3" id="GB_Required">
                      <label
                        htmlFor="expected-iops"
                        className=" text-sm"
                      >
                        <p className=" flex items-center italic font-sans">
                          4.Total GB required by the application <p className=" text-gray-300 mx-1">|</p>
                          <a
                            href="https://drive.google.com/file/d/1M_W72io12Ho3cgBy2j3X3aEVln7UMfmw/view?usp=drivesdk"
                            className="text-blue-500 mx-2 flex items-center underline"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <TbInfoTriangle className="mt-1" />
                            info
                          </a>{" "}
                          |
                          <span
                            className="mx-2 flex items-center cursor-pointer text-blue-500 underline"
                            onClick={handleContactUsClick4}
                          >
                            <MdOutlinePhone className="mt-1 mr-1" /> Contact us
                          </span>
                        </p>
                        <div className="focus:border-gray-500 border-gray-500 mt-3">
                          <input
                            type="text"
                            id="app-type-text"
                            name="TypeOfApplication"
                            {...register('GB_Required')}
                            placeholder="Enter Total GB required by the application"
                            required
                            className="bg-white border focus:border-gray-300 border-gray-400 text-sm focus:ring-blue-500 block w-full p-2.5"
                          />
                        </div>
                        {showContactInfo4 && (
                          <div className="mt-2 text-green-900 font-semibold">
                            Ph: 1234567890 | e-mail: support@gxinetworks.com
                          </div>
                        )}
                      </label>
                    </div>



                    <div>
                      {/* <div className="form-group mt-2 py-2" id="frequency">
                        <label
                          htmlFor="frequency"
                          className=" text-sm"
                        >
                          <p className="flex items-center italic font-sans">
                            {" "}
                            5. Do you need dynamic IOPs to be configured during
                            specific time <br />
                            of the day / week / Month?<p className=" text-gray-300 mx-1">|</p>
                            <a
                              href="https://drive.google.com/file/d/1bDfaqixjApdzapwh6FpRRYfM4I_SX5DF/view?usp=sharing"
                              className="text-blue-500 mx-2 flex items-center underline"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <TbInfoTriangle className="mt-1" />
                              info
                            </a>{" "}
                            |
                            <span
                              className="mx-2 flex items-center cursor-pointer text-blue-500 underline"
                              onClick={handleContactUsClick5}
                            >
                              <MdOutlinePhone className="mt-1 mr-1" /> Contact
                              us
                            </span>
                          </p>
                          <div className="focus:border-gray-500 border-gray-500 mt-3">
                            <input
                              type="text"
                              id="app-type-text"
                              name="TypeOfApplication"
                              {...register('needDynamic_IOPs')}
                              placeholder="Enter you need dynamic IOPs"
                              required
                              className="bg-white border focus:border-gray-300 border-gray-400 text-sm focus:ring-blue-500 block w-full p-2.5"
                            />
                          </div>
                          {showContactInfo5 && (
                            <div className="mt-2 text-green-900 font-semibold">
                              Ph: 1234567890 | e-mail: support@gxinetworks.com
                            </div>
                          )}
                        </label>
                      </div> */}

<div className="form-group mt-2 py-2 items-center italic font-sans" id="frequency">
        <label htmlFor="frequency" className=" text-sm">
        <p className=" flex items-center italic font-sans">
        5. Do you need dynamic IOPs to be configured during specific time of <br/> the day / week / Month? <p className=" text-gray-300 mx-2 mb-5">|</p>
                          <a
                            href="https://drive.google.com/file/d/1NnMpam2IvrlOskUMs5GiP4kHA0NN-t72/view?usp=drivesdk"
                            className="text-blue-500 mx-2 flex items-center underline mb-5"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <TbInfoTriangle className="mt-1" />
                            info
                          </a>{" "}
                         <p className=" mb-5">|</p>
                          <span
                            className="mx-2 flex items-center mb-5 cursor-pointer text-blue-500 underline"
                            onClick={handleContactUsClick5}
                          >
                            <MdOutlinePhone className="mt-1 mr-1" /> Contact us
                          </span>
                        </p>



        
        </label>
        <select
          id="dq5"
          name="frequency"
          {...register('frequency')}
          className="bg-gray-50 text-gray-900 mt-3 text-sm focus:border-gray-500 border-gray-500 border block w-full p-2.5"
        ><option value="no">No</option>
          <option value="yes">Yes</option>
          
        </select>
        {showContactInfo5 && (
                          <div className="mt-2 text-green-900 font-semibold text-sm">
                            Ph: 1234567890 | e-mail: support@gxinetworks.com
                          </div>
                        )}

        {selectedValues.frequency === "yes" && (
          <div id="nq51" className="mt-2">
            <label htmlFor="frequency" className=" text-sm">Select Frequency:</label>
            <select
              id="frequency"
              name="needDynamic_IOPs"
              className="bg-gray-50 text-gray-900 text-sm mt-2  focus:border-gray-500 border-gray-500 border block w-full p-2.5"
              {...register('needDynamic_IOPs')}
            >
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
           
            <label htmlFor="time" className=" text-sm">Select Time:</label>
            <select
              id="time"
              name="time"
              className="bg-gray-50 mt-2 text-gray-900 text-sm focus:border-gray-500 border-gray-500 border-2 block w-full p-2.5"
              {...register('time')}
            >
              {/* Time options here */}
              <option value="01:30">01:30 AM</option>
              <option value="02:00">02:00 AM</option>
              <option value="02:30">02:30 AM</option>
              <option value="03:00">03:00 AM</option>
          <option value="03:30">03:30 AM</option>
          <option value="04:00">04:00 AM</option>
          <option value="04:30">04:30 AM</option>
          <option value="05:00">05:00 AM</option>
          <option value="05:30">05:30 AM</option>
          <option value="06:00">06:00 AM</option>
          <option value="06:30">06:30 AM</option>
          <option value="07:00">07:00 AM</option>
          <option value="07:30">07:30 AM</option>
          <option value="08:00">08:00 AM</option>
          <option value="08:30">08:30 AM</option>
          <option value="09:00">09:00 AM</option>
          <option value="09:30">09:30 AM</option>
          <option value="10:00">10:00 AM</option>
          <option value="10:30">10:30 AM</option>
          <option value="11:00">11:00 AM</option>
          <option value="11:30">11:30 AM</option>
          <option value="12:00">12:00 PM</option>
          <option value="12:30">12:30 PM</option>
          <option value="13:00">01:00 PM</option>
          <option value="13:30">01:30 PM</option>
          <option value="14:00">02:00 PM</option>
          <option value="14:30">02:30 PM</option>
          <option value="15:00">03:00 PM</option>
          <option value="15:30">03:30 PM</option>
          <option value="16:00">04:00 PM</option>
          <option value="16:30">04:30 PM</option>
          <option value="17:00">05:00 PM</option>
          <option value="17:30">05:30 PM</option>
          <option value="18:00">06:00 PM</option>
          <option value="18:30">06:30 PM</option>
          <option value="19:00">07:00 PM</option>
          <option value="19:30">07:30 PM</option>
          <option value="20:00">08:00 PM</option>
          <option value="20:30">08:30 PM</option>
          <option value="21:00">09:00 PM</option>
          <option value="21:30">09:30 PM</option>
          <option value="22:00">10:00 PM</option>
          <option value="22:30">10:30 PM</option>
          <option value="23:00">11:00 PM</option>
          <option value="23:30">11:30 PM</option>
            </select>
          </div>
        )}

        {validationErrors.frequency && (
          <div className="mt-2 text-red-500">
            {validationErrors.frequency.message}
          </div>
        )}
      </div>





                      <div className="form-group   py-2" id="AWS_Account_ID">
                        <label
                          htmlFor="AWS_Account_ID"
                          className=" text-sm"
                        >
                          <p className="flex items-center italic font-sans">
                            {" "}
                            6. AWS Account ID where you would deploy the
                            solution<p className=" text-gray-300 mx-1">|</p>
                            <a
                              href="https://drive.google.com/file/d/1bDfaqixjApdzapwh6FpRRYfM4I_SX5DF/view?usp=sharing"
                              className="text-blue-500 mx-2 flex items-center underline"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <TbInfoTriangle className="mt-1" />
                              info
                            </a>{" "}
                            |
                            <span
                              className="mx-2 flex items-center cursor-pointer text-blue-500 underline"
                              onClick={handleContactUsClick6}
                            >
                              <MdOutlinePhone className="mt-1 mr-1" /> Contact
                              us
                            </span>
                          </p>
                          <div className="focus:border-gray-500 border-gray-500 mt-3">
                            <input
                              type="text"
                              id="app-type-text"
                              name="TypeOfApplication"
                              {...register('AWS_Account_ID')}
                              placeholder="Enter AWS Account ID"
                              required
                              className="bg-white border focus:border-gray-300 border-gray-400 text-sm focus:ring-blue-500 block w-full p-2.5"
                            />
                          </div>
                          {showContactInfo6 && (
                            <div className="mt-2 text-green-900 font-semibold">
                              Ph: 1234567890 | e-mail: support@gxinetworks.com
                            </div>
                          )}
                        </label>
                      </div>
                    </div>

                    <div className="form-group py-2" id="ou">
  <label htmlFor="ou" className="text-sm">
    <p className="flex items-center italic font-sans">
      7. OU (Organizational Unit) 
      <p className="text-gray-300 mx-1">|</p>
      <a
        href="https://drive.google.com/file/d/1AT2Tfo0IjyH3tWlPVQqa_7HXlk3Q9Klk/view?usp=sharing"
        className="text-blue-500 mx-2 flex items-center underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        <TbInfoTriangle className="mt-1" />
        info
      </a>{" "}
      |
      <span
        className="mx-2 flex items-center cursor-pointer text-blue-500 underline"
        onClick={handleContactUsClick7}
      >
        <MdOutlinePhone className="mt-1 mr-1" /> Contact us
      </span>
    </p>

    <div className="focus:border-gray-500 border-gray-500 mt-3">
      <input
        type="text"
        id="ou"
        {...register('ou')}
        name="ou"
        placeholder="Enter Organizational Unit"
        required
        className="bg-white border focus:border-gray-300 border-gray-400 text-sm focus:ring-blue-500 block w-full p-2.5"
      />
    </div>
    {showContactInfo7 && (
      <div className="mt-2 text-green-900 font-semibold">
        Ph: 1234567890 | e-mail: support@gxinetworks.com
      </div>
    )}
  </label>
</div>

<div className="form-group mt-2" id="region">
  <label htmlFor="region" className="text-sm">
    <p className="flex items-center italic font-sans">
      8. AWS Region where the application is deployed 
      <p className="text-gray-300 mx-1">|</p>
      <a
        href="https://drive.google.com/file/d/1tnlJSNEX4iReKzAu3vtuMfvUCZylScLt/view?usp=sharing"
        className="text-blue-500 mx-2 flex items-center underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        <TbInfoTriangle className="mt-1" />
        info
      </a>{" "}
      |
      <span
        className="mx-2 flex items-center cursor-pointer text-blue-500 underline"
        onClick={handleContactUsClick8}
      >
        <MdOutlinePhone className="mt-1 mr-1" /> Contact us
      </span>
    </p>
    <div className="focus:border-gray-500 border-gray-500 mt-3">
      <input
        type="text"
        id="region"
        {...register('region')}
        name="region"
        placeholder="Enter AWS Region"
        required
        className="bg-white border focus:border-gray-300 border-gray-400 text-sm focus:ring-blue-500 block w-full p-2.5"
      />
    </div>
    {showContactInfo8 && (
      <div className="mt-2 text-green-900 font-semibold">
        Ph: 1234567890 | e-mail: support@gxinetworks.com
      </div>
    )}
  </label>
</div>



                      <div>
                      <div className="form-group mt-4" id="VPC_Name_ID">
                        <label htmlFor="vpcs" className=" text-sm">
                          <p className="flex items-center italic font-sans">
                            9. VPC Name & ID <p className=" text-gray-300 mx-1">|</p>
                            <a
                              href="https://drive.google.com/file/d/1V5dx0teSlY0bzSQJlQk-bMPBDsOAcbBm/view?usp=sharing"
                              className="text-blue-500 mx-2 flex items-center underline"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <TbInfoTriangle className="mt-1" />
                              info
                            </a>{" "}
                            |
                            <span
                              className="mx-2 flex items-center cursor-pointer text-blue-500 underline"
                              onClick={handleContactUsClick9}
                            >
                              <MdOutlinePhone className="mt-1 mr-1" /> Contact
                              us
                            </span>
                          </p>
                          <div className="focus:border-gray-500 border-gray-500 mt-3">
                            <input
                              type="text"
                              id="app-type-text"
                              name="TypeOfApplication"
                              {...register('VPC_Name_Name')}
                              placeholder="Enter VPC Name & ID"
                              required
                              className="bg-white border focus:border-gray-300 border-gray-400 text-sm focus:ring-blue-500 block w-full p-2.5"
                            />
                          </div>
                          {showContactInfo9 && (
                            <div className="mt-2 text-green-900 font-semibold">
                              Ph: 1234567890 | e-mail: support@gxinetworks.com
                            </div>
                          )}
                        </label>
                      </div>
                    </div>

                    <div
                      className="form-group py-2 mt-2"
                      id="Securit_Group_Name"
                    >
                      <label
                        htmlFor="Securit_Group_Name"
                        className=" text-sm"
                      >
                        <p className="flex items-center italic font-sans">
                          10. Security Group Name <p className=" text-gray-300 mx-1">|</p>
                          <a
                            href="https://drive.google.com/file/d/1-uutS7IEHMwvSJ0pV0MymlRKBK5yTK6X/view?usp=drive_link"
                            className="text-blue-500 mx-2 flex items-center underline"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <TbInfoTriangle className="mt-1" />
                            info
                          </a>{" "}
                          |
                          <span
                            className="mx-2 flex items-center cursor-pointer text-blue-500 underline"
                            onClick={handleContactUsClick10}
                          >
                            <MdOutlinePhone className="mt-1 mr-1" /> Contact us
                          </span>
                        </p>

                        <div className="focus:border-gray-500 border-gray-500 mt-3">
                          <input
                            type="text"
                            id="app-type-text"
                            name="TypeOfApplication"
                            {...register(' Securit_Group_Name')}
                            placeholder="Enter Security Group"
                            required
                            className="bg-white border focus:border-gray-300 border-gray-400 text-sm focus:ring-blue-500 block w-full p-2.5"
                          />
                        </div>
                        {showContactInfo10 && (
                          <div className="mt-2 text-green-900 font-semibold">
                            Ph: 1234567890 | e-mail: support@gxinetworks.com
                          </div>
                        )}
                      </label>
                    </div>

                    <div className="form-group mt-1 py-2" id="Placement_Group">
                      <label
                        htmlFor="Placement_Group"
                        className=" text-sm"
                      >
                        <p className="flex items-center italic font-sans">
                          {" "}
                          11. Placement Group <p className=" text-gray-300 mx-1">|</p>
                          <a
                            href="https://drive.google.com/file/d/1PvM8Nwoqp1YFeJN_x2wK9FJzdJk2CFHx/view?usp=sharing"
                            className="text-blue-500 mx-2 flex items-center underline"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <TbInfoTriangle className="mt-1" />
                            info
                          </a>{" "}
                          |
                          <span
                            className="mx-2 flex items-center cursor-pointer text-blue-500 underline"
                            onClick={handleContactUsClick11}
                          >
                            <MdOutlinePhone className="mt-1 mr-1" /> Contact us
                          </span>
                        </p>
                        <div className="focus:border-gray-500 border-gray-500 mt-3">
                          <input
                            type="text"
                            id="app-type-text"
                            name="TypeOfApplication"
                            {...register('Placement_Group')}
                            placeholder="Enter Placement Group"
                            required
                            className="bg-white border focus:border-gray-300 border-gray-400 text-sm focus:ring-blue-500 block w-full p-2.5"
                          />
                        </div>
                        {showContactInfo11 && (
                          <div className="mt-2 text-green-900 font-semibold">
                            Ph: 1234567890 | e-mail: support@gxinetworks.com
                          </div>
                        )}
                      </label>
                    </div>

                    <div className="form-group py-2 w-full" id="submit">
                      <input
                        type="submit"
                        value="Submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                      />
                    </div>

                    {/* <ChatBot2/> */}
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* <ChatBot/> */}
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Form;
