import React, { useState, useEffect } from 'react';
import './components/tailwind.css';

const ChatBot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isOpen, setIsOpen] = useState(true); // Set isOpen to true initially
  const [showPopup, setShowPopup] = useState(true);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    contactNumber: '',
    companyName: '',
  });

  const questions = [
    // 'Welcome to Globalxperts! I am super excited to assist you today. Are you interested in talking to me?',
    'May I know your full name?',
    `Nice to meet you, ${userInfo.name ? userInfo.name : ''}. What is your official email address?`,
    'Thank you! 2 more questions and we are good to go! Please share your contact number.',
    "Finally, what's the name of the organization you work for?",
  ];

  const sendMessage = () => {
    if (input.trim()) {
      const newMessages = [...messages, { text: input, sender: 'user' }];
      setMessages(newMessages);
      console.log(input,currentQuestionIndex,questions.length);
      if (currentQuestionIndex < questions.length) {
  
        const nextQuestion =
          currentQuestionIndex === 1
            ? `Nice to meet you, ${input} What is your official email address?`
            : questions[currentQuestionIndex];
        setMessages([...newMessages, { text: nextQuestion, sender: 'bot' }]);
        setInput('');

        setCurrentQuestionIndex(currentQuestionIndex + 1);

        // Save user info from questions 1-4
        if (currentQuestionIndex === 0) {
          setUserInfo({ ...userInfo, name: input });
          console.log(input,"hjefh");
        } else if (currentQuestionIndex === 1) {
          setUserInfo({ ...userInfo, email: input });
        } else if (currentQuestionIndex === 2) {
          setUserInfo({ ...userInfo, contactNumber: input });
        } 
      } else {
        setMessages([
          ...newMessages,
          {
            text:
              'Thank you for the information. Our Sales Team will be in touch with you soon.',
            sender: 'bot',
          },
        ]);
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => setShowPopup(false), 5000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (currentQuestionIndex === 0 && isOpen) {
      const initialQuestion = questions[currentQuestionIndex];
      setMessages([{ text: initialQuestion, sender: 'bot' }]);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  }, [isOpen]);

  return (
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      {isOpen && (
        <div className="w-96  border border-gray-300 rounded-lg shadow-lg  ">
          <div className="p-4 border-b border-gray-300 flex justify-between items-center bg-blue-400 rounded">
            <h2 className="text-lg font-bold text-center">Welcome to Globalxperts! I am super excited to assist you today. </h2>
            
          </div>
          <div className="p-4 h-60 overflow-y-auto">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`mb-2 ${
                  message.sender === 'user' ? 'text-right' : 'text-left'
                }`}
              >
                <span
                  className={`inline-block p-2 rounded-lg ${
                    message.sender === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-200'
                  }`}
                >
                  {message.text}
                </span>
              </div>
            ))}
          </div>
          <div className="p-4 border-t border-gray-300 flex">
            <input
              type="text"
              className="flex-1 p-2 border border-gray-300 rounded-lg"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => (e.key === 'Enter' ? sendMessage() : null)}
            />
            <button
              onClick={sendMessage}
              className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-lg"
            >
              Send
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBot;
