import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoIosArrowBack } from "react-icons/io";

const SignUp = () => {
  const [submitted, setSubmitted] = useState(false);
  const [hasAWSAccount, setHasAWSAccount] = useState(true); // Default to "Yes"
  const [emailForBoarding, setEmailForBoarding] = useState('');

  const schema = yup.object().shape({
    email: yup.string().required('Email is required'),
    pass: yup.string().required("Password is required"),
    aws: yup.string()
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    context: { hasAWSAccount },
  });

  const { register, handleSubmit, formState: { errors }, reset } = methods;

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    if (!hasAWSAccount) {
      navigate("/Boarding", { state: { email: data.email } }); // Pass email to Boarding
      return;
    }

    const formData = {
      email: data.email,
      pass: data.pass,
      aws: data.aws || '' // Include aws only if it exists, otherwise set it to an empty string
    };
  
    const response = await fetch("https://prolific-be.globalxperts.net/SignUp", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData)
    });
  
    const responseData = await response.json();
    if (response.ok) {
      toast.success('Sign up successful, letting you in!', {
        position: "top-center",
        transition: Slide
      });
      reset();
      setSubmitted(true);
    } else {
      window.alert(responseData.message); // Show the error message from the backend
    }
  };

  useEffect(() => {
    if (submitted) {
      const timer = setTimeout(() => {
        navigate("/form",);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [submitted, navigate]);

  return (
    <div className="bg-white rounded-md p-4 w-full h-screen">
      <ToastContainer />
      <div className='w-full'>
        <button className="flex items-center">
          <Link to="/" className='flex'>
            <IoIosArrowBack className="text-xl font-bold mt-1" />
            <span className="ml-1 font-bold">Back</span>
          </Link>
        </button>
      </div>
      <div className="flex flex-col items-center justify-center w-full h-full mt-4">
        <img
          className="h-32 w-52"
          src={require("../asset/Gxi.png")}
          alt="Gxi Logo"
        />
        <h1 className="text-2xl font-bold mb-4">Create Your Account</h1>
        <FormProvider {...methods}>
          <form className="w-full max-w-sm px-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <input
                type="text"
                id="email"
                placeholder='Email address'
                className="w-full border border-gray-300 rounded-md p-2"
                {...register('email')}
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
            </div>
            <div className="mb-4">
              <input
                type="password"
                id="password"
                placeholder='Password'
                className="w-full border border-gray-300 rounded-md p-2"
                {...register('pass')}
              />
              {errors.pass && <p className="text-red-500 text-sm">{errors.pass.message}</p>}
            </div>
            <div className="mb-4">
              <span className='font-normal'>Do you have an AWS Account?</span>
              <div className='flex'>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="awsAccount"
                    value="yes"
                    className="mr-2"
                    checked={hasAWSAccount}
                    onChange={() => setHasAWSAccount(true)}
                  />
                  Yes
                </label>
                <label className="flex items-center ml-4">
                  <input
                    type="radio"
                    name="awsAccount"
                    value="no"
                    className="mr-2"
                    checked={!hasAWSAccount}
                    onChange={() => setHasAWSAccount(false)}
                  />
                  No
                </label>
              </div>
            </div>
            {hasAWSAccount && (
              <div className="mb-4">
                <input
                  type="text"
                  id="aws"
                  placeholder='AWS Account No.'
                  className="w-full border border-gray-300 rounded-md p-2"
                  {...register('aws')}
                />
                {/* {errors.aws && <p className="text-red-500 text-sm">{errors.aws.message}</p>} */}
              </div>
            )}

            <button type="submit" className="w-full bg-blue-500 text-white rounded-md p-2 hover:bg-blue-600">Continue</button>
          </form>
        </FormProvider>
        
        <div className="mt-4 text-center py-5">
          <p className="text-gray-500">Already have an account? <Link to="/SignIn" className="text-blue-500">Sign in</Link></p>
          <div className="flex items-center justify-center w-full mt-2 border-b border-gray-300 pb-2">
            <span className="mr-2">OR</span>
          </div>
          <button type='button' className="w-full bg-gray-200 text-gray-700 rounded-md p-2 mt-4 hover:bg-gray-300">Continue with Github</button>
        </div>

      </div>
    </div>
  );
}

export default SignUp;
