import React, { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from './AuthContext'; // Import AuthContext
import { IoIosArrowBack } from "react-icons/io";

const SignIn = () => {
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext); // Use the AuthContext

  const schema = yup.object().shape({
    // email: yup.string().email("Invalid email format").required("Email is required"),
    // pass: yup.string().required("Password is required"),
  });

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data) => {
    const response = await fetch("https://prolific-be.globalxperts.net/SignIn", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    });
    if (response.ok) {
      const user = await response.json();
      localStorage.setItem('userEmail', user.email); // Storing only the email
      console.log(user, "this is data");
      setUser(user); // Update the context with the signed-in user
      toast.success('Sign in successful!', {
        position: "top-center",
        transition: Slide
      });
      reset();
      setSubmitted(true);
    } else {
      window.alert('Failed to sign in.');
    }
  };
  
  useEffect(() => {
    if (submitted) {
      const timer = setTimeout(() => {
        navigate("/form");
      }, 3000); // 3 seconds delay for the toast to be displayed
      return () => clearTimeout(timer); // Clear the timer on component unmount
    }
  }, [submitted, navigate]);

  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <div className="bg-white shadow-md rounded-md p-4 w-full min-h-screen flex flex-col justify-center items-center">
      <ToastContainer />

      <div className='w-full'>
        <button onClick={handleBackClick} className="flex items-center mb-4">
          <IoIosArrowBack className="text-xl font-bold" />
          <span className="ml-1 font-bold">Back</span>
        </button>
      </div>

      <div className="flex flex-col items-center justify-center w-full px-4 sm:px-6 lg:px-8">
        <img
          className="h-32 w-52"
          src={require("./asset/Gxi.png")}
          alt=""
        />

        <h1 className="text-2xl font-bold mb-4">Welcome</h1>
        <h1 className='text-md font-normal mb-4'>Sign in for Prolific to continue</h1>
        <form className="w-full max-w-sm" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <input 
              type="email" 
              id="email" 
              placeholder='Email address' 
              className="w-full border border-gray-300 rounded-md p-2"   
              {...register('email')}
            />
            {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
          </div>
          <div className="mb-4">
            <input 
              type="password" 
              id="password" 
              placeholder='Password' 
              className="w-full border border-gray-300 rounded-md p-2" 
              {...register('pass')}
            />
            {errors.pass && <p className="text-red-500 text-sm">{errors.pass.message}</p>}
            <span className='font-semibold text-blue-400'>Forget password?</span>
          </div>
          <button className="w-full bg-blue-500 text-white rounded-md p-2 hover:bg-blue-600" type='submit'>Continue</button>
        </form>


        <div className="mt-4 text-center w-full">
          <p className="text-gray-500">Don't have an account? <Link to="/SignUp" className="text-blue-500">Sign up</Link></p>
        <div className="flex items-center justify-center w-full mt-2 border-b border-gray-300 pb-2">
            <span className="mr-2">OR</span>
          </div>
          <button className="w-1/4 bg-gray-200 text-gray-700 rounded-md p-2 mt-4 hover:bg-gray-300">Continue with Github</button>
        </div>



      </div>
    </div>
  );
}

export default SignIn;
