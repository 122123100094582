import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./components/tailwind.css";

const ChatBot3 = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(true);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    contactNumber: '',
    companyName: ''
  });
  const [firstInteraction, setFirstInteraction] = useState(true);

  const questions = [
    'May I know your full name?',
    `Nice to meet you, ${userInfo.name}. What is your official email address?`,
    'Thank you! 2 more questions and we are good to go! Please share your contact number.',
    "Finally, what's the name of the organization you work for?",
  ];

  const sendMessage = () => {
    if (input.trim()) {
      const newMessages = [...messages, { text: input, sender: 'user' }];
      setMessages(newMessages);
      setInput('');

      // Save user info based on the current question
      if (currentQuestionIndex === 1) {
        setUserInfo({ ...userInfo, name: input });
      } else if (currentQuestionIndex === 2) {
        setUserInfo({ ...userInfo, email: input });
      } else if (currentQuestionIndex === 3) {
        setUserInfo({ ...userInfo, contactNumber: input });
      } else if (currentQuestionIndex === 4) {
        setUserInfo({ ...userInfo, companyName: input });
      }

      // Determine the next question or finish the conversation
      if (currentQuestionIndex < questions.length) {
        const nextQuestion =
          currentQuestionIndex === 1
            ? `Nice to meet you, ${input}. What is your official email address?`
            : questions[currentQuestionIndex];
        setMessages([...newMessages, { text: nextQuestion, sender: 'bot' }]);
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        setMessages([...newMessages, { text: 'Noted and Thank you for the information. Our Sales Team will be in touch with you soon.', sender: 'bot' }]);
        saveUserData(); // Save the user data after the final question
      }
    }
  };

  const saveUserData = async () => {
    try {
      console.log(userInfo, "ewfhiuwugu");
      const response = await axios.post('https://prolific-be.globalxperts.net/Cform', userInfo, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('User data saved successfully:', response.data);
    } catch (error) {
      console.error('Error saving user data:', error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => setShowPopup(false), 5000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (currentQuestionIndex === 0 && (isOpen || firstInteraction)) {
      const initialQuestion = questions[currentQuestionIndex];
      setMessages([{ text: initialQuestion, sender: 'bot' }]);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  }, [isOpen, firstInteraction]);

  useEffect(() => {
    if (firstInteraction) {
      setIsOpen(true);
      setFirstInteraction(false);
    }
  }, [firstInteraction]);

  return (
    <div className="fixed bottom-4 right-4">
      {isOpen ? (
        <div className="w-80 bg-white border border-gray-300 rounded-lg shadow-lg">
          <div className="p-4 border-b border-gray-300 flex justify-between items-center">
            <h2 className="text-lg font-bold">GlobalXperts Bot</h2>
            <button onClick={() => setIsOpen(false)} className=" text-4xl text-red-500">×</button>
          </div>
          <div className="p-4 h-64 overflow-y-auto">
            {messages.map((message, index) => (
              <div key={index} className={`mb-2 ${message.sender === 'user' ? 'text-right' : 'text-left'}`}>
                <span className={`inline-block p-2 rounded-lg ${message.sender === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
                  {message.text}
                </span>
              </div>
            ))}
          </div>
          <div className="p-4 border-t border-gray-300 flex">
            <input
              type="text"
              className="flex-1 p-2 border border-gray-300 rounded-lg"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => (e.key === 'Enter' ? sendMessage() : null)}
            />
            <button onClick={sendMessage} className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-lg">Send</button>
          </div>
        </div>
      ) : (
        <div className="relative">
          <button
            onClick={() => setIsOpen(true)}
            className="bg-blue-500 text-white p-4 rounded-full shadow-lg focus:outline-none animate-blink"
          >
            💬
          </button>
        </div>
      )}
    </div>
  );
};

export default ChatBot3;
