// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SignUp from './components/SignUp';
import SignIn from './SignIn';
import form from './Form';
import { AuthProvider } from './AuthContext';
import Form from './Form';
import OnBoarding from './OnBoarding';
import ChatBot from './Chat';
import ChatBoatForm from './ChatBoatForm';
import ContactUs from './ContactUs';
import Verification from './Verification';
//import Api from './Api';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route exact path='/' element={<App />} />
          <Route exact path='/SignUp' element={<SignUp />} />
          <Route exact path='/SignIn' element={<SignIn />} />
          <Route exact path='/form' element={<Form/>} />
          <Route exact path='/Boarding' element={<OnBoarding/>} />
          <Route exact path='/ChatBot' element={<ChatBot/>} />
          <Route exact path='/chat' element={<ChatBoatForm/>} />
          <Route exact path='/ContactUs' element={<ContactUs/>} />
          <Route path="/verify/:userId/:token" element={<Verification />} />

         
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();
