import React from "react";
import { Element } from 'react-scroll';
import { useInView } from 'react-intersection-observer';
import 'animate.css/animate.min.css';
import 'animate.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import lightningBoltIcon from '../asset/workload.png';
import baselineIcon from '../asset/baseline.png';
import dynamicRampIcon from '../asset/business.png';
import scalesIcon from '../asset/overload.png';

function Content() {
  const [headingRef, headingInView] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [cardsRef, cardsInView] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [benefitsRef, benefitsInView] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [architectureRef, architectureInView] = useInView({ triggerOnce: true, threshold: 0.2 });

  const benefits = [
    {
      text: "Reduce AWS block storage TCO by 70% by scaling IOPS on demand.",
    },
    {
      text: "Migrate high-IOPS workloads to AWS with EBS accelerator for better TCO.",
    },
    {
      text: "Achieve or exceed current on-premise IOPS levels on AWS for these applications.",
    },
  ];

  const cardsContent = [
    {
      description: "Some customers have workloads on-premise which need 2M+ IOPS for their block storage and 10Gbps+ peak throughput.",
    },
    {
      description: `IOPS experience spikes for limited periods, which can lead to increased costs                 if high IOPS are maintained constantly.`,
    },
    {
      description: "To reduce this, Prolific I/O can boost throughput and IOPS during peak times while maintaining baseline performance at other times.",
    },
  ];

  return (
    <>
      <div className="relative flex flex-col font-custom bg-gradient-to-b from-gray-50 to-gray-200 min-h-screen">
        {/* HEADING */}
        <Element name="heading" className="px-12 2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-1">
          <section ref={headingRef} className={`mt-12 text-center ${headingInView ? 'animate__animated animate__fadeInDown' : ''}`}>
            <div className="mt-5 text-black">
              <h2 className="text-6xl font-extrabold leading-tight">PROLIFIC I/O</h2>
              <p className="mt-3 text-xl text-gray-700">High performance block storage at any scale</p>
            </div>
          </section>
        </Element>

        <div className="w-full flex justify-center">
          <hr className="border-t border-gray-400 opacity-100 my-8" style={{ width: '80%' }} />
        </div>

        {/* Cards */}
        <Element name="cards" className="px-12 2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-1">
          <section
            ref={cardsRef}
            className={`mt-3 mb-3 ${cardsInView ? 'animate__animated animate__zoomIn' : ''}`}
          >
            <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-8">
              <div className="relative p-8 rounded-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
                <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-blue-600 opacity-75 rounded-lg"></div>
                <div className="relative bg-white bg-opacity-80 p-6 rounded-lg shadow-md transform transition duration-500 hover:bg-opacity-90">
                  <p className="font-consul text-center text-lg text-gray-800 font-medium  h-full">Some customers have workloads on-premise which need 2M+ IOPS for their block storage and 10Gbps+ peak throughput.</p>
                </div>
              </div>
              <div className="relative p-8 rounded-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
                <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-blue-600 opacity-75 rounded-lg"></div>
                <div className="relative bg-white bg-opacity-80 p-6 rounded-lg shadow-md transform transition duration-500 hover:bg-opacity-90">
                  <p className="font-consul text-center text-lg text-gray-800 font-medium py-3 h-full">IOPS experience spikes for limited periods, which can lead to increased costs if high IOPS are maintained constantly.</p>
                </div>
              </div>
              <div className="relative p-8 rounded-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
                <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-blue-600 opacity-75 rounded-lg"></div>
                <div className="relative bg-white bg-opacity-80 p-6 rounded-lg shadow-md transform transition duration-500 hover:bg-opacity-90">
                  <p className="font-consul text-center text-lg text-gray-800 font-medium h-full">
                  To reduce this, Prolific I/O can boost throughput and IOPS during peak times while maintaining baseline performance at other times.</p>
                </div>
              </div>
            </div>
          </section>
        </Element>


        <div className="w-full flex justify-center">
          <hr className="border-t border-gray-400 opacity-100 my-8" style={{ width: '80%' }} />
        </div>

        {/* Benefits Section */}
        <Element name="benefits" className="px-12 2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-1">
          <h2 className="text-5xl font-expressive400 font-bold mb-6 text-gray-800 text-center my-5">Benefits</h2>
          <section ref={benefitsRef} className="w-full mt-20">
            <div className={`grid lg:grid-cols-3 gap-12 mb-20 ${benefitsInView ? 'animate__animated animate__fadeInLeft' : ''}`}>
              <div className="relative p-10 rounded-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
                <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-blue-600 opacity-75 rounded-lg"></div>
                <div className="relative bg-white bg-opacity-75 p-8 rounded-lg shadow-md transform transition duration-500 hover:bg-opacity-90">
                  <div className="flex flex-col items-center text-center relative z-10">
                    <p className="font-consul mb-4 text-lg text-gray-900 py-4">Reduce AWS block storage TCO by 70% by scaling IOPS on demand.</p>
                  </div>
                </div>
              </div>
              <div className="relative p-10 rounded-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
                <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-blue-600 opacity-75 rounded-lg"></div>
                <div className="relative bg-white bg-opacity-75 p-8 rounded-lg shadow-md transform transition duration-500 hover:bg-opacity-90">
                  <div className="flex flex-col items-center text-center relative z-10">
                    <p className="font-consul mb-4 text-lg text-gray-900 ">
                    Experience enhanced performance and cost-efficiency in IOPs by using PIOSA</p>
                  </div>
                </div>
              </div>
              <div className="relative p-10 rounded-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
                <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-blue-600 opacity-75 rounded-lg"></div>
                <div className="relative bg-white bg-opacity-75 p-8 rounded-lg shadow-md transform transition duration-500 hover:bg-opacity-90">
                  <div className="flex flex-col items-center text-center relative z-10">
                    <p className="font-consul mb-4 text-lg text-gray-900">Achieve or exceed current on-premise IOPS levels on AWS for these applications.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Element>


        <div className="w-full flex justify-center">
          <hr className="border-t border-gray-400 opacity-100 my-8" style={{ width: '80%' }} />
        </div>

        {/* Architecture Section */}
        <Element name="architecture" className="px-12 2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-1">
          <div ref={architectureRef} className={`grid lg:grid-cols-2 gap-12 mb-20 ${architectureInView ? 'animate__animated animate__fadeInLeft' : ''}`}>
            <div className="transition-transform">
              <img
                className="w-full h-full rounded-lg object-cover"
                src={require("../asset/Arch.png")}
                alt="Architecture"
              />
            </div>
            <div className="transition-transform">
              <h2 className="text-5xl font-expressive400 font-bold mb-6 p-2">Architecture</h2>
              {[
                "Run your workload with spikey IOPS on accelerated servers.",
                "Scale IOPS on demand using an acceleration controller.",
                "Scale your storage IOPS using 1 to 15 acceleration controllers.",
                "Dynamically control storage IOPS by turning off one or more acceleration controllers.",
              ].map((point, index) => (
                <p key={index} className="font-consul text-xl p-2 mb-3 mx-2">
                  {point}
                </p>
              ))}
            </div>
          </div>
        </Element>

        <div className="w-full flex justify-center">
          <hr className="border-t border-gray-400 opacity-100 my-8" style={{ width: '80%' }} />
        </div>
      </div>
    </>
  );
}

export default Content;
