import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CiCloud } from "react-icons/ci";
import Content from "./components/Content";
import { AuthContext } from "./AuthContext";
import { useInView } from "react-intersection-observer";
import 'animate.css/animate.min.css';
import 'animate.css';
import ChatBot3 from "./Chatbot3";  // Import the ChatBot3 component
import ChatBot from "./Chat";
import { BsTelephoneOutbound } from "react-icons/bs";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [showChatBot, setShowChatBot] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(AuthContext);


  const questions = [
    "Are your throughput demands causing server performance issues?",
    "Are your high storage IOPS increasing your cost on AWS?",
    "Are your workloads consuming 3 to 4 times the normal storage IOPS for a few hours daily or a few days monthly?"
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000 // Adjust speed as needed (in milliseconds)
  };


  const [headerRef, headerInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [mainRef, mainInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [footerRef, footerInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [imageRef, imageInView] = useInView({ triggerOnce: true, threshold: 0.1 });

  const { ref: headingRef, inView: headingInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: paragraph1Ref, inView: paragraph1InView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: paragraph2Ref, inView: paragraph2InView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: paragraph3Ref, inView: paragraph3InView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  useEffect(() => {
    if (location.state && location.state.showChatBot) {
      setShowChatBot(true);
    }
  }, [location.state]);

  return (
    <div className="App">
      <div className="2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-1 w-full h-16 bg-white flex items-center justify-between px-4 shadow-lg animate__animated animate__fadeInDown">
  <h1 className="font-custom font-semibold text-3xl bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 bg-clip-text text-transparent drop-shadow-lg">
    <img className="h-16 w-32 sm:h-32 sm:w-52" src={require("./asset/Gxi.png")} alt="Gxi" />
  </h1>
  <div className="flex space-x-4 items-center">
    <button className="bg-blue-500 text-white px-2 py-2 sm:px-4 sm:py-3 font-semibold rounded flex items-center border-solid hover:animate__animated hover:animate__pulse"
      onClick={() => navigate("/ContactUs")}>
      <BsTelephoneOutbound className="mr-2 text-lg sm:text-2xl font-semibold text-black" />
      Contact Us
    </button>

    <button
      className="bg-white text-blue-400 px-2 py-2 sm:px-4 sm:py-2 rounded hover:animate__animated hover:animate__shakeX"
      onClick={() => setIsOpen(!isOpen)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 6h16M4 12h16m-7 6h7"
        />
      </svg>
    </button>

    {user ? (
      <h1 className="text-lg mt-4">
        <span className="text-transparent bg-gradient-to-r from-black via-blue-400 to-blue-500 bg-clip-text font-semibold">
          {user.email}
        </span>
      </h1>
    ) : null}
  </div>

  {isOpen && (
    <div className="bg-blue-400 flex-col items-center py-4 animate__animated animate__fadeInDown">
      {["Platform", "Products", "Developers", "Solutions", "Resources", "Company"].map((item, index) => (
        <h1 key={index} className="text-white px-4 py-2 rounded mt-2">
          {item}
        </h1>
      ))}
    </div>
  )}
</div>


      {isOpen && (
        <div className="bg-blue-400 flex-col items-center py-4 animate__animated animate__fadeInDown">
          {["Platform", "Products", "Developers", "Solutions", "Resources", "Company"].map((item, index) => (
            <h1 key={index} className="text-white px-4 py-2 rounded mt-2">
              {item}
            </h1>
          ))}
        </div>
      )}



<header ref={headerRef} className={`bg-black py-6 sm:py-10 px-4 sm:px-5 lg:px-10 h-auto text-white ${headerInView ? 'animate__animated animate__fadeInDown' : ''}`}>
  <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center">
    <div className="text-center sm:text-left">
      <h1 className="text-xl sm:text-2xl lg:text-3xl font-custom text-neon shadow-neon font-semibold">
        Prolific I/O - Storage Accelerator (IOSA)
      </h1>
    </div>
  </div>
  <div className="container mx-auto px-4 text-center mt-6 sm:mt-12">
    <p className="text-2xl sm:text-3xl lg:text-4xl font-semibold font-sans">On-Demand I/O Scaling on</p>
    <p className="text-2xl sm:text-3xl lg:text-4xl font-semibold font-sans">AWS Block Storage</p>
    <p className="mt-3 text-sm sm:text-lg text-gray-400">Cloud speed, on-prem performance</p>
    
    <Slider {...settings} className="mt-3 text-lg sm:text-xl lg:text-2xl text-white bg-opacity-50 bg-red-950 py-3 font-bold rounded-2xl">
      {questions.map((question, index) => (
        <div key={index}>
          <p className="mt-2">{question}</p>
        </div>
      ))}
    </Slider>

    {!user && (
      <div className="flex justify-center mt-5 sm:mt-10 mb-2">
        <button
          className="bg-red-700 text-white font-semibold px-2 py-2 sm:px-4 sm:py-3 rounded flex items-center border-solid hover:animate__animated hover:animate__pulse"
          onClick={() => navigate("/SignUp")}
        >
          <CiCloud className="mr-2 text-xl sm:text-2xl" />
          Sign Up
        </button>
      </div>
    )}
  </div>
</header>



      <div className="text-black h-40 bg-black pt-12 w-full flex align-middle justify-center text-center">
        <img
          ref={imageRef}
          src="https://www.datocms-assets.com/2885/1675117188-products-overview-hero-boundary.svg"
          alt="Prolific I/O"
          className={`${imageInView ? 'animate__animated animate__zoomIn' : ' '}` }
          
        />
      </div>

      <main ref={mainRef} className={`${mainInView ? 'animate__animated animate__fadeInUp' : ''}`}>
        <Content />
      </main>

      <div className="bg-gradient-to-b grid lg:grid-cols-2 from-purple-600 to-blue-600 py-10 text-black shadow-lg">
        <div className="col-span-1 mt-28">
          <h1 className={`font-extrabold text-black font-consul text-center py-5 pt-5 ${headingInView ? 'animate__animated animate__fadeInLeft' : ''}`}>
            <p className="font-extrabold mb-2 py-2 text-5xl text-white">Try Prolific I/O</p>
            <p className="py-2 text-6xl">to Solve Your</p>
            <p className="py-2 text-6xl">Business Problems</p>
          </h1>

          {/* {!user && (
            <div className="w-full grid justify-center">
              <button
                className="text-white text-center bg-gradient-to-r mt-14 from-pink-500 to-red-500 px-8 py-3 rounded-full font-bold shadow-md transform transition duration-300 hover:scale-105"
                onClick={() => navigate("/SignUp")}
              >
                Sign Up
              </button>
            </div>
          )} */}
        </div>

        <div className="col-span-1 mt-7 mx-3">
          <p
            ref={paragraph1Ref}
            className={`mb-6 mr-3 text-white text-3xl font-consul font-bold border-2 p-2 bg-black rounded-lg from-purple-600 to-blue-600 border-black ${paragraph1InView ? 'animate__animated animate__fadeInLeft' : ''}`}
            style={{ animationDelay: '0.5s' }}
          >
             Are your throughput demands causing server performance issues?
         
          </p>

          <p
            ref={paragraph2Ref}
            className={`mb-6 text-white mr-3 text-3xl font-consul font-bold border-2 p-2 bg-black rounded-lg border-black ${paragraph2InView ? 'animate__animated animate__fadeInLeft' : ''}`}
            style={{ animationDelay: '1s' }}
          >
             Are your workloads consuming 3 to 4 times the normal storage IOPS for a few hours daily or a few days monthly?
            {/* Are your workloads consuming 3 to 4 times the storage IOPS for several days a month? */}
          </p>

          <p
            ref={paragraph3Ref}
            className={`mb-6 text-white mr-3 text-3xl py-4 font-consul font-bold rounded-lg border-2 p-2 bg-black border-black ${paragraph3InView ? 'animate__animated animate__fadeInLeft' : ''}`}
            style={{ animationDelay: '1.5s' }}
          >
           Are your high storage IOPS increasing your cost on AWS?
          </p>
        </div>
      </div>

      <footer ref={footerRef} className={`bg-black text-white p-2 2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-2 sm:col-span-2 ${footerInView ? 'animate__animated animate__fadeInUp' : ''}`}>
        <div className="flex items-center justify-between 2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-2 sm:col-span-2">
          <div>
            <span className="text-xl p-4 font-semibold">
              To Connect with our Team
            </span>
            <br />
            <div className="flex items-center mt-4">
              <input
                type="text"
                placeholder="Email Address"
                className="bg-black text-white border border-gray-600 px-2 py-2 rounded-l focus:outline-none "
              />
              <button className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-r p-4 mx-2">
                Submit
              </button>
            </div>
          </div>
          <div className=" mr-20 mt-12 text-2xl font-semibold">Prolific I/O</div>
        </div>
        <div className="border-t-2 mt-5">
          <div className="bg-black text-white">
            <div className="flex justify-center space-x-4 p-4">
              <a
                href="#"
                className="text-blue-400 hover:text-blue-600 font-semibold"
              >
                Privacy Policy
              </a>
              <a
                href="#"
                className="text-blue-400 hover:text-blue-600 font-semibold"
              >
                Terms of Service
              </a>
              <a
                href="#"
                className="text-blue-400 hover:text-blue-600 font-semibold"
              >
                Contact
              </a>
            </div>
            <div className="text-center p-4 text-gray-400">
              &copy; {new Date().getFullYear()} Prolific I/O. All rights reserved.
            </div>
          </div>
        </div>
      </footer>

      {showChatBot && (
        <div className="fixed bottom-4 right-4">
          <ChatBot3 />
          {/* <ChatBot/> */}
        </div>
      )}
    </div>
  );
}

export default App;
